import { useEffect, useState } from 'react'
import OrderLineItem from './OrderLineItem/OrderLineItem'
import { Pagination } from 'swiper/modules'
import { getCustomerOrders } from '@/clients/shopify/client'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/scss'
import 'swiper/scss/pagination'
import './RecentPurchases.scss'
import { variantIsSubscription } from '@/utilities/commerce'

const ORDERS_PER_PAGE = 200
const DISPLAYED_PER_PAGE = 3

const RecentPurchases = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [orders, setOrders] = useState<any[]>([])
  const [displayedOrders, setDisplayedOrders] = useState<any[]>([])
  const [displayedOrdersCount, setDisplayedOrdersCount] =
    useState(DISPLAYED_PER_PAGE)

  const fetchOrders = async () => {
    setIsLoading(true)
    try {
      const result = await getCustomerOrders(ORDERS_PER_PAGE)

      let formattedOrders = result.edges
        .flatMap((order: any) =>
          order.node.lineItems.edges.map((edge: any) => ({
            ...edge.node,
            statusUrl: order.node.statusUrl,
          })),
        )
        .filter((lineItem) => {
          // If item is not a subscription, not an add-on, and has a price greater than 0
          if (
            !variantIsSubscription(lineItem?.variant?.sku) &&
            !lineItem?.variant?.sku.includes('ADDON') &&
            lineItem?.variant?.price.amount > 0
          ) {
            return true
          }

          return false
        })

      setOrders(formattedOrders)
      setDisplayedOrders(formattedOrders.slice(0, displayedOrdersCount))
      setDisplayedOrdersCount(
        (prevDisplayedOrdersCount) =>
          prevDisplayedOrdersCount + DISPLAYED_PER_PAGE,
      )
    } catch (error) {
      console.error('Error fetching orders', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchOrders()
  }, [])

  const loadAdditionalOrders = async () => {
    setDisplayedOrdersCount(
      (prevDisplayedOrdersCount) =>
        prevDisplayedOrdersCount + DISPLAYED_PER_PAGE,
    )
    setDisplayedOrders(orders.slice(0, displayedOrdersCount))
  }

  if (!orders.length) return null

  return (
    <div className="c-recent-purchases">
      <h2 className="c-recent-purchases__header type-label">
        Recent Purchases
      </h2>
      <div className="c-recent-purchases__list">
        <div className="c-recent-purchases__list-wrapper">
          {displayedOrders.map((orderLineItem, index) => {
            return (
              <div key={index} className="c-recent-purchases__item">
                <OrderLineItem orderLineItem={orderLineItem} />
              </div>
            )
          })}
        </div>
        {displayedOrders.length < orders.length && (
          <button
            className={`c-recent-purchases__load button button--primary o-loadable o-loadable--small o-loadable--inverted ${
              isLoading ? 'is-loading' : ''
            }`}
            onClick={loadAdditionalOrders}
          >
            Load More Orders
          </button>
        )}
      </div>
      <Swiper
        className="swiper-container"
        modules={[Pagination]}
        spaceBetween={32}
        slidesPerView={1}
        pagination={{
          el: '#c-recent-purchases__swiper-pagination',
          clickable: true,
        }}
        onReachEnd={loadAdditionalOrders}
      >
        {displayedOrders.map((orderLineItem, index) => {
          return (
            <SwiperSlide key={index} className="swiper-slide">
              <OrderLineItem orderLineItem={orderLineItem} />
            </SwiperSlide>
          )
        })}
      </Swiper>
      <div
        id="c-recent-purchases__swiper-pagination"
        className="swiper-pagination"
      />
    </div>
  )
}

export default RecentPurchases
