import { useState, useRef } from 'react'
import Icon from '../Icon/Icon'
import './ButtonDropdown.scss'

const ButtonDropdown = ({ buttonText, children }: any) => {
  const [isOn, setIsOn] = useState(false)
  const dropdownRef = useRef<any>(null)

  const toggle = () => setIsOn(!isOn)
  const toggleOff = () => setIsOn(false)

  const onFocusOut = (event: any) => {
    const isDropdownInFocus = dropdownRef.current.contains(event.relatedTarget)
    if (!isDropdownInFocus) {
      toggleOff()
    }
  }

  return (
    <div ref={dropdownRef} className="c-button-dropdown" onBlur={onFocusOut}>
      <button
        className={`c-button-dropdown__button button button--secondary button--small ${
          isOn ? 'is-active' : ''
        }`}
        onClick={toggle}
        onKeyDown={(event) => event.key === 'Escape' && toggleOff()}
      >
        <span className="c-button-dropdown__button-text">
          {buttonText}{' '}
          <i
            className={`c-button-dropdown__icon ${
              isOn ? 'icon--arrow-up' : 'icon--arrow-down'
            }`}
          >
            <Icon name={'select-arrow'} />
          </i>
        </span>
      </button>
      {isOn && <div className="c-button-dropdown__options">{children}</div>}
    </div>
  )
}

export default ButtonDropdown
