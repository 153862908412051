import { useStore } from '@nanostores/react'
import { useState, useMemo, useEffect } from 'react'

import { fetchSubscriptionSystems } from '@/clients/pops/client'
import ButtonDropdown from '@/components/ButtonDropdown/ButtonDropdown'
import { SystemWeek } from '@/models/system-week'
import { $getCampaignsBySubscriptionId } from '@/stores/account/computed'
import { loadSystemGroups } from '@/stores/system-group/operations'
import dayjs from '@/utilities/dayjs-config'
import imgUrl from '@/utilities/imgUrl'
import { formatDate, formatMeals } from '@/utilities/sakara'

import SubscriptionCardNotice from '@/components/SubscriptionCardNotice/SubscriptionCardNotice'
import FailedPaymentNotice from '../FailedPaymentNotice/FailedPaymentNotice'
import SubscriptionCard from '../SubscriptionCard/SubscriptionCard'

import './MealProgramSubscriptionCard.scss'

const IMAGE_SRC = imgUrl(
  'https://cdn.shopify.com/s/files/1/0192/0076/files/meal-program-subscription.png?v=1650377978',
  '200x200',
)

const MealProgramSubscriptionCard = ({ subscription }: any) => {
  const [isExtraInfoLoading, setIsExtraInfoLoading] = useState(false)
  const [skippedWeeks, setSkippedWeeks] = useState<any>([])
  const [systemWeeks, setSystemWeeks] = useState<SystemWeek[]>([])

  const getCampaignsBySubscriptionId = useStore($getCampaignsBySubscriptionId)

  const calendarUrl = useMemo(() => {
    return `/calendar?subscription_id=${subscription.id}`
  }, [subscription.id])

  const accountUrl = useMemo(() => {
    return `/account?tab_id=subscriptions&subscription_id=${subscription.id}`
  }, [subscription.id])

  const currentMenuUrl = useMemo(() => {
    return `/calendar?subscription_id=${subscription.id}&view_current_menu=true`
  }, [subscription.id])

  const renameSubscriptionUrl = useMemo(() => {
    return `/account?tab_id=subscriptions&subscription_id=${subscription.id}&action=edit_subscription_name`
  }, [subscription.id])

  const deliveryZoneNotice = useMemo(() => {
    return subscription.deliveryZone?.notice
  }, [subscription?.deliveryZone?.notice])

  const campaigns = useMemo(() => {
    return getCampaignsBySubscriptionId(subscription.id) || []
  }, [getCampaignsBySubscriptionId, subscription])

  useEffect(() => {
    const fetchSystemWeeks = async () => {
      await loadSystemGroups()
      const rawSystemWeeks = await fetchSubscriptionSystems(subscription.id)

      const sortedSystemWeeks = rawSystemWeeks
        .map((sw) => new SystemWeek(sw))
        .sort((a, b) => a.week.unix() - b.week.unix())

      setSystemWeeks(sortedSystemWeeks)
    }

    fetchSystemWeeks()
  }, [subscription])

  const upcomingSystemWeeks = useMemo(() => {
    const today = dayjs()

    return systemWeeks.filter(
      (sw) => sw.week.isAfter(today) && !subscription.isCancelled,
    )
  }, [systemWeeks, subscription])

  const upcomingSystemWeek = useMemo(() => {
    if (!upcomingSystemWeeks.length) return null

    return upcomingSystemWeeks[0]
  }, [upcomingSystemWeeks])

  const upcomingSystem = useMemo(() => {
    if (!upcomingSystemWeek) return null

    return upcomingSystemWeek.system
  }, [upcomingSystemWeek])

  const upcomingCampaigns = useMemo(() => {
    const today = dayjs()

    return campaigns.filter((campaign) => {
      const { programLength, optInStatus } = campaign
      const campaignStartWeek = optInStatus!.campaignStartWeek

      const campaignEndWeek = dayjs(campaignStartWeek).add(
        programLength - 1,
        'weeks',
      )
      const endOfCampaign = campaignEndWeek.endOf('week')

      return endOfCampaign.isAfter(today) && !subscription.isCancelled
    })
  }, [campaigns, subscription])

  const upcomingCampaign = useMemo(() => {
    if (!upcomingCampaigns.length) return null

    return upcomingCampaigns[0]
  }, [upcomingCampaigns])

  const upcomingCampaignText = useMemo(() => {
    if (!upcomingCampaign) return ''

    const campaignStartWeek = upcomingCampaign.optInStatus!.campaignStartWeek

    return `${
      upcomingCampaign.config?.title || upcomingCampaign.name
    } starting ${campaignStartWeek.format('M/D')}`
  }, [upcomingCampaign])

  const upcomingSystemText = useMemo(() => {
    if (!upcomingSystem) return ''

    const name =
      upcomingSystem.systemGroup?.config?.title ||
      upcomingSystem.systemGroup?.name

    return `${name} starting ${upcomingSystemWeek?.startWeek.format('M/D')}`
  }, [upcomingSystem, upcomingSystemWeek])

  const upcomingText = useMemo(() => {
    let text

    if (upcomingCampaign && upcomingSystem && upcomingSystemWeek) {
      const campaignStartWeek = upcomingCampaign.optInStatus!.campaignStartWeek
      const { startWeek: systemStartWeek } = upcomingSystemWeek

      if (systemStartWeek.isBefore(campaignStartWeek)) {
        text = upcomingSystemText
      } else {
        text = upcomingCampaignText
      }
    } else if (upcomingSystem) {
      text = upcomingSystemText
    } else if (upcomingCampaign) {
      text = upcomingCampaignText
    }

    return text
  }, [
    upcomingCampaign,
    upcomingSystem,
    upcomingSystemText,
    upcomingCampaignText,
  ])

  const buttons = subscription.isCancelled ? (
    <>
      <a href={accountUrl} className="button button--secondary button--small">
        Reactivate
      </a>
      <ButtonDropdown buttonText="More">
        <a href={accountUrl}>View Subscription Details</a>
      </ButtonDropdown>
    </>
  ) : (
    <>
      <a href={calendarUrl} className="button button--secondary button--small">
        Edit calendar
      </a>

      <ButtonDropdown buttonText="More">
        <a href={currentMenuUrl}>View Current Menu</a>
        <a href={accountUrl}>Update Default Settings</a>
        <a href="/account?tab_id=order-history">View Order History</a>
        <a href={renameSubscriptionUrl}>Rename Subscription</a>
      </ButtonDropdown>
    </>
  )

  const cardTitle = subscription.name || 'Your Meal Program'

  const info = (
    <>
      <span className="type-label">
        {formatMeals(subscription.meals, ' + ')}
      </span>
      <span className="type-body"> {subscription.daysOfFood} Days / Week</span>
      {upcomingText && (
        <span className="c-meal-program-subscription-card__upcoming-campaign type-label">
          {upcomingText}
        </span>
      )}
    </>
  )

  const isUpcomingWeekSkipped = () => {
    const nextBillingDate = dayjs(subscription.nextBillingDate)
    const nextWeekToBeBilled = nextBillingDate.add(1, 'week').startOf('week')

    return skippedWeeks.includes(nextWeekToBeBilled.format())
  }

  const timeUntilMealChangeCutoff = () => {
    const today = dayjs()
    const daysInDecimal = subscription.nextBillingDate.diff(today, 'days', true)
    const days = Math.trunc(daysInDecimal)
    const hours = Math.trunc((daysInDecimal % 1) * 24)

    return `${days} Days, ${hours} Hours`
  }

  const getExtraInfo = () => {
    if (isExtraInfoLoading) {
      return (
        <div className="c-meal-program-subscription-card__extra-info-placeholder" />
      )
    }

    if (subscription.isCancelled) {
      return (
        <span className="type-label">
          Subscription cancelled{' '}
          {formatDate(subscription.endDate, 'MM/DD/YYYY')}
        </span>
      )
    }

    if (isUpcomingWeekSkipped()) {
      return (
        <>
          Your deliveries are <strong className="type-label">paused</strong> for
          next week.
        </>
      )
    }

    return (
      <>
        Review next week within{' '}
        <strong className="type-label">{timeUntilMealChangeCutoff()}</strong>
      </>
    )
  }

  const getNotice = () => {
    if (subscription.isCancelled) {
      return null
    }

    if (subscription.failedPayment) {
      return <FailedPaymentNotice subscription={subscription} />
    } else if (deliveryZoneNotice) {
      return (
        <SubscriptionCardNotice
          type="custom"
          backgroundColor={deliveryZoneNotice.bgColour}
          textColor={deliveryZoneNotice.textColour}
        >
          {deliveryZoneNotice.title && (
            <strong>{deliveryZoneNotice.title}:</strong>
          )}
          {deliveryZoneNotice.text}
          {deliveryZoneNotice.ctaUrl && (
            <a href={deliveryZoneNotice.ctaUrl}>{deliveryZoneNotice.ctaText}</a>
          )}
        </SubscriptionCardNotice>
      )
    }
  }

  return (
    <div className="c-meal-program-subscription-card">
      <SubscriptionCard
        imageSrc={IMAGE_SRC}
        isCancelled={subscription.isCancelled}
        notice={getNotice()}
        title={cardTitle}
        info={info}
        extraInfo={getExtraInfo()}
        buttons={buttons}
      />
    </div>
  )
}

export default MealProgramSubscriptionCard
