import { useMemo } from 'react'
import SubscriptionCard from '../SubscriptionCard/SubscriptionCard'
import FailedPaymentNotice from '../FailedPaymentNotice/FailedPaymentNotice'
import ButtonDropdown from '@/components/ButtonDropdown/ButtonDropdown'
import { formatDate } from '@/utilities/sakara'
import dayjs from '@/utilities/dayjs-config'
import './CleanBoutiqueSubscriptionCard.scss'

const CleanBoutiqueSubscriptionCard = ({ subscription }: any) => {
  const accountUrl = useMemo(() => {
    return `/account?tab_id=subscriptions&subscription_id=${subscription.id}`
  }, [subscription.id])

  const buttons = subscription.isCancelled ? (
    <>
      <a href={accountUrl} className="button button--secondary button--small">
        Reactivate
      </a>
      <ButtonDropdown buttonText="More">
        <a href={accountUrl}>View Subscription Details</a>
      </ButtonDropdown>
    </>
  ) : (
    <>
      <a href={accountUrl} className="button button--secondary button--small">
        Edit renewal
      </a>

      <ButtonDropdown buttonText="More">
        <a href={accountUrl}>Update Default Settings</a>
        <a href="/account?tab_id=order-history">View Order History</a>
        <a href="/collections/products/category-subscribe">
          Shop All Subscriptions
        </a>
      </ButtonDropdown>
    </>
  )

  const cardTitle = subscription.name || subscription.productName

  const info = (
    <>
      <span className="type-body">Billed Monthly</span>
    </>
  )

  const getExtraInfo = () => {
    if (subscription.isCancelled) {
      return (
        <span className="type-label">
          Subscription cancelled{' '}
          {formatDate(subscription.endDate, 'MM/DD/YYYY')}
        </span>
      )
    }

    return (
      <>
        Next Renewal on{' '}
        <strong className="type-label">
          {dayjs(subscription.nextBillingDate).format('dddd, MMMM D')}
        </strong>
      </>
    )
  }

  const getNotice = () => {
    if (subscription.isCancelled) {
      return null
    }

    if (subscription.failedPayment) {
      return <FailedPaymentNotice subscription={subscription} />
    }
  }

  return (
    <div className="c-clean-boutique-subscription-card">
      <SubscriptionCard
        imageSrc={subscription.image}
        isCancelled={subscription.isCancelled}
        notice={getNotice()}
        title={cardTitle}
        info={info}
        extraInfo={getExtraInfo()}
        buttons={buttons}
      />
    </div>
  )
}

export default CleanBoutiqueSubscriptionCard
